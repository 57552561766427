import React, { useState } from 'react';
import styled from 'styled-components';
import { Slot, SlotType } from './types';
import { backgroundPrimary, backgroundSecondary, blueHighContrast, blueLowContrast, borderPrimary, contentPrimary, contentSecondary, grayHighContrast, grayLowContrast, greenHighContrast, greenLowContrast, interactivePrimary, yellowHighContrast, yellowLowContrast } from '../Colors';
import * as Dialog from '@radix-ui/react-dialog';
import { ChevronUpIcon, ChevronDownIcon, CheckIcon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import { StyledDialogOverlay, StyledDialogContent, StyledDialogCloseButton, StyledSaveButton, StyledDialogTitle, StyledDialogHeader } from '../styles';
import { ISlotInfo, SlotStatus } from '@driver-backup/lib';
import { SlotRequestNotes } from './SlotRequestNotes';

const StyledSlotRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${backgroundPrimary};
  margin-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  gap: 16px;
  align-self: stretch;
  box-shadow: 0px 2px 8px 0px rgba(27, 0, 82, 0.06), 0px 2px 4px 0px rgba(27, 0, 82, 0.06), 0px 1px 2px 0px rgba(27, 0, 82, 0.06), 0px 1px 1px 0px rgba(27, 0, 82, 0.06), 0px 0px 0.5px 0.5px rgba(27, 0, 82, 0.06);

  @media (min-width: 801px) {
    border-radius: 8px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  color: ${contentPrimary};
`;

const StyledTime = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: ${contentPrimary};
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 1;
`;

const StyledAddress = styled.a`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: ${interactivePrimary};
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 1;
`;

const StyledSubtitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: ${contentPrimary};
`;

const StyledPopoverRoot = styled(Popover.Root)`
  align-items: center;
  position: relative;
`

const StyledPopoverTrigger = styled(Popover.Trigger)`
  padding: 16px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;
  gap: 8px;
`;


const StyledPopoverContent = styled(Popover.Content)`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 32px 0px rgba(27, 0, 82, 0.08), 0px 4px 24px 0px rgba(27, 0, 82, 0.08), 0px 4px 16px 0px rgba(27, 0, 82, 0.08), 0px 2px 8px 0px rgba(27, 0, 82, 0.08), 0px 2px 4px 0px rgba(27, 0, 82, 0.08), 0px 1px 2px 0px rgba(27, 0, 82, 0.08), 0px 1px 1px 0px rgba(27, 0, 82, 0.08), 0px 0px 0.5px 0.5px rgba(27, 0, 82, 0.08);  overflow: hidden;
  position: absolute;
  right: -12px;
  transform: translate(60px, 8px);
  z-index: 1000;
  min-width: 120px;
  padding: 8px 0px;
`;

const StyledPopoverItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${contentPrimary};
  padding: 12px 12px;
  display: flex;
  align-items: left;
  cursor: pointer;
  gap: 12px;

  &:hover {
    background-color: ${grayLowContrast};
  }
`;

const StyledActionContainer = styled.div`
  display: flex;
  gap: 8px;
  height: fit-content;
  flex-shrink: 0;
  margin-left: auto;
  align-items: flex-start;
`;

const StyledEditNotesButton = styled.button`
  font-size: 16px;
  font-weight: 700;
  background-color: transparent;
  border: ${borderPrimary};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  height: 100%;
  padding: 16px 12px;
  flex-shrink: 0;
  &:hover {
    background-color: ${backgroundSecondary};
  }
 &:active {
    background-color: ${grayLowContrast};
  }
`;

const StyledTextArea = styled.textarea`
  margin: 0;
  min-height: 76px;
  resize: vertical;
  padding: 16px;
  font-size: 16px;
  font-family: inherit;
  border: 1px solid ${borderPrimary};
  border-radius: 8px;
  border-width: 1px;

  &::placeholder {
    font-family: inherit;
    color: ${contentSecondary};
  }

  &:focus {
    outline: none;
    border-color: ${interactivePrimary};
    box-shadow: 0 0 0 2px ${interactivePrimary};
  }
`;

const getSlotTitle = (slot: Slot): string => {
  switch (slot.slotType) {
    case SlotType.DriverBreak:
      return 'Break';
    case SlotType.Pickup:
      return `Pickup ${getRiderName(slot)}`;
    case SlotType.Dropoff:
      return `Dropoff ${getRiderName(slot)}`;
    case SlotType.StartLocation:
      return 'Start Location';
    case SlotType.EndLocation:
      return 'End Location';
    default:
      return 'Unknown Slot Type';
  }
};

const getRiderName = (slot: Slot): string => {
  let name = '';
  if (slot.riderFirstName) {
    name += slot.riderFirstName;
  }
  if (slot.riderLastName) {
    if (name) {
      name += ' ';
    }
    name += `${slot.riderLastName}`;
  }
  return name;
};

const formatTime = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const getSlotTime = (slot: Slot): string => {
  if (slot.slotType === SlotType.DriverBreak && slot.breakLength) {
    const startTime = formatTime(slot.scheduledTs).replace(/^0/, '');
    const endTime = formatTime(slot.scheduledTs + slot.breakLength).replace(/^0/, '');
    return `${startTime} - ${endTime}`;
  }
  return formatTime(slot.scheduledTs).replace(/^0/, '');
};

const getAccessibilityInfo = (slot: Slot): string => {
  if (!slot.accessibilityFeatures || slot.accessibilityFeatures.length === 0) {
    return 'No accessibility features';
  }
  return slot.accessibilityFeatures.map(feature => `${feature.count} ${feature.type}`).join(', ');
};

const getSlotSubtitle = (slot: Slot): string => {
    switch (slot.slotType) {
        case SlotType.Pickup:
        case SlotType.Dropoff:
            return `${getFareInfo(slot)} • ${getNumRiders(slot)} • ${getAccessibilityInfo(slot)} • ${getSlotPhoneNumber(slot)}`;
        default:
            return '';
    }
};

const getFareInfo = (slot: Slot): string => {
  return slot.fare ?? ''
};

const getNumRiders = (slot: Slot): string => {
    return slot.riders
        .map(rider => `${rider.count} ${rider.type}${rider.count > 1 ? 's' : ''}`)
        .join(', ');
};

const getSlotPhoneNumber = (slot: Slot): string => {
  return slot.riderPhoneNumber ?? 'no registered phone number';
};

const getStatusColor = (status: SlotStatus): string => {
  switch (status) {
    case SlotStatus.Scheduled:
      return grayLowContrast;
    case SlotStatus.Completed:
      return greenLowContrast;
    case SlotStatus.Cancelled:
      return yellowLowContrast;
    case SlotStatus.InProgress:
      return blueLowContrast;
    default:
      return contentSecondary;
  }
};

const getStatusTextColor = (status: SlotStatus): string => {
  switch (status) {
    case SlotStatus.Scheduled:
      return grayHighContrast;
    case SlotStatus.Completed:
      return greenHighContrast;
    case SlotStatus.Cancelled:
      return yellowHighContrast;
    case SlotStatus.InProgress:
      return blueHighContrast;
    default:
      return contentSecondary;
  }
};

const getSlotInfo = (slot: Slot): ISlotInfo => {
  const localStorageSlotInfo = localStorage.getItem(`slot_${slot.id}_data`);
  if (localStorageSlotInfo) {
    return JSON.parse(localStorageSlotInfo);
  }

  return  {
    slotStatus: slot.slotStatus,
    slotName: getSlotTitle(slot),
    slotInProgressTs: null,
    slotCompletedTs: null,
    slotCancelledTs: null,
    slotNotes: '',
  }
}

const SlotRow: React.FC<{ slot: Slot }> = ({ slot }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [slotInfo, setSlotInfo] = useState<ISlotInfo>(getSlotInfo(slot));
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleSlotInfoChange = (newInfo: Partial<ISlotInfo>) => {
    setSlotInfo((prevInfo) => {
      const updatedInfo = { ...prevInfo, ...newInfo }
      localStorage.setItem(`slot_${slot.id}_data`, JSON.stringify(updatedInfo));
      return updatedInfo;
    });
  }

  const handleStatusChange = (newStatus: SlotStatus) => {
    switch (newStatus) {
      case SlotStatus.Completed:
        handleSlotInfoChange({ slotStatus: newStatus, slotCompletedTs: Date.now() });
        return;
      case SlotStatus.InProgress:
        handleSlotInfoChange({ slotStatus: newStatus, slotInProgressTs: Date.now() });
        return;
      case SlotStatus.Cancelled:
        handleSlotInfoChange({ slotStatus: newStatus, slotCancelledTs: Date.now() });
        return;
    }
  }

  const currentStatus = slotInfo.slotStatus;
  const notes = slotInfo.slotNotes;

  return (
    <StyledSlotRow>
      <StyledHeader>
        <StyledTitle>{getSlotTitle(slot)}</StyledTitle>
        <StyledTime>{getSlotTime(slot)}</StyledTime>
      </StyledHeader>
      <StyledContent>
        <StyledInfo>
            { slot.scheduledAddress && (
                <StyledAddress href={`https://www.google.com/maps/search/?api=1&query=${(slot.scheduledAddress ?? '').replace(' ', '+')}`} target="_blank" rel="noopener noreferrer">
                    {slot.scheduledAddress} ↗
                </StyledAddress>
            )}
          <StyledSubtitle>{getSlotSubtitle(slot)}</StyledSubtitle>
        </StyledInfo>
        <StyledActionContainer>
          <StyledEditNotesButton onClick={() => setIsDialogOpen(true)}>
            Edit Notes
          </StyledEditNotesButton>
          <StyledPopoverRoot open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <StyledPopoverTrigger style={{ backgroundColor: getStatusColor(currentStatus), color: getStatusTextColor(currentStatus)}}>
              {currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1)}
              {isPopoverOpen ? <ChevronUpIcon width={20} height={20} /> : <ChevronDownIcon width={20} height={20} />}
            </StyledPopoverTrigger>
            <StyledPopoverContent>
              {Object.values(SlotStatus).map((status) => (
                <StyledPopoverItem 
                  key={status}
                  onClick={() => {
                    handleStatusChange(status);
                    setIsPopoverOpen(false);
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', width: '20px' }}>
                    { status === currentStatus ? <CheckIcon width={20} height={20} /> : null }  
                  </div>
                  {status.charAt(0).toUpperCase() + status.slice(1)}                    
                </StyledPopoverItem>
              ))}
            </StyledPopoverContent>
          </StyledPopoverRoot>
        </StyledActionContainer>
      </StyledContent>
      <SlotRequestNotes slot={slot} />
      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Portal>
          <StyledDialogOverlay />
          <StyledDialogContent>
            <StyledDialogHeader>
              <StyledDialogTitle>Edit Notes</StyledDialogTitle>
              <StyledDialogCloseButton onClick={() => setIsDialogOpen(false)}>Close</StyledDialogCloseButton>
            </StyledDialogHeader>
            <StyledTextArea
              value={notes}
              onChange={(e) => handleSlotInfoChange({ slotNotes: e.target.value })}
              placeholder="Write a note..."
            />
            <StyledSaveButton onClick={() => setIsDialogOpen(false)}>Save</StyledSaveButton>
          </StyledDialogContent>
        </Dialog.Portal>
      </Dialog.Root>
    </StyledSlotRow>
  );
};

export default SlotRow;
