import React, { ReactNode, useState } from 'react'
import styled, { keyframes } from 'styled-components'

interface IProps {
  onClick: () => Promise<unknown> | unknown
  className?: string
  children?: ReactNode
}

export const AsyncButton = ({ onClick, className, children }: IProps) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <button 
      className={className} 
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'center',
        ...(isLoading && {
          color: '#545f87',
          backgroundColor: '#f5f6fa'
        })
      }}
      onClick={() => {
        if (isLoading) {
          return
        }

        setIsLoading(true)

        void (async () => {
          try {
            await onClick()
          } catch (error) {

          }

          setIsLoading(false)
        })()
      }}
    >
      {isLoading && <LoaderSpinner />}
      {children}
    </button>
  )
}

const loadSpin = keyframes`
  to {
    transform: rotate(360deg);
  }
`
export const LoaderSpinner = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-color: #545f87 #545f87 #545f87 transparent;
  animation: ${loadSpin} 0.8s linear infinite;
  z-index: 9999;
`
