import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { contentSecondary, contentPrimary,contentInverted, interactivePrimary, borderSecondary, errorPrimary } from "./Colors";
import * as Form from "@radix-ui/react-form";
import './index.css';
import { TERMS_OF_SERVICE_LINK } from './constants/links';

const TopLevelDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  max-width: 600px;
`;

const StyledH2 = styled.h2`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: ${contentSecondary};
`;

const StyledH1 = styled.h1`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  margin: 0;
  color: ${contentPrimary};
`;

const StyledForm = styled(Form.Root)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledFormSubmit = styled(Form.Submit)`
  background-color: ${interactivePrimary};
  color: ${contentInverted};
  padding: 16px 24px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 700;
`;

const StyledFormField = styled(Form.Field)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledFormLabel = styled(Form.Label)`
  font-size: 16px;
  font-weight: 400;
  color: ${contentPrimary};
`;

const StyledFormControl = styled(Form.Control)`
  font-size: 16px;
  font-weight: 400;
  color: ${contentPrimary};
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${borderSecondary};
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${interactivePrimary};
  }
`;

const StyledText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${contentSecondary};
  margin: 0;
`;

interface LoginProps {
  login: (organizationKey: string, username: string, password: string) => Promise<void>;
  loginError: string | null;
}

export const Login: React.FC<LoginProps> = ({ login, loginError }) => {
  const [organizationKey, setOrganizationKey] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const savedOrganizationKey = localStorage.getItem('organizationKey');
    const savedUsername = localStorage.getItem('username');
    if (savedOrganizationKey) setOrganizationKey(savedOrganizationKey);
    if (savedUsername) setUsername(savedUsername);
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    localStorage.setItem('organizationKey', organizationKey);
    localStorage.setItem('username', username);
    login(organizationKey, username, password);
  };

  return (
    <TopLevelDiv className="App">
      <ContentWrapper>
        <header className="App-header">
          <StyledH2>Disaster Recovery Driver Interface</StyledH2>
          <StyledH1>Log In</StyledH1>
        </header>
        <StyledForm onSubmit={handleSubmit}>
          <StyledFormField name="organizationKey">
            <StyledFormLabel>Organization Key</StyledFormLabel>
            <StyledFormControl type="text" value={organizationKey} onChange={(e) => setOrganizationKey(e.target.value)} required />
          </StyledFormField>
          <StyledFormField name="username">
            <StyledFormLabel>Username</StyledFormLabel>
            <StyledFormControl type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
          </StyledFormField>
          <StyledFormField name="password">
            <StyledFormLabel>Disaster Recovery Password</StyledFormLabel>
            <StyledFormControl type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            <StyledText>This is a special password issued by your organization for use in emergencies, not your usual password.</StyledText>
          </StyledFormField>
          {loginError && (
            <div style={{ color: `${errorPrimary}` }}>
              {loginError}
            </div>
          )}
          <StyledFormSubmit>
            Log In
          </StyledFormSubmit>
        </StyledForm>
        <StyledText>By continuing, you agree to the <a href={TERMS_OF_SERVICE_LINK}>Terms & Conditions</a>.</StyledText>
      </ContentWrapper>
    </TopLevelDiv>
  );
}
