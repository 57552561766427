import { SlotStatus } from "@driver-backup/lib";

export interface AccessibilityFeature {
  type: string;
  count: number;
}

export enum RiderType {
    Adult = 'adult',
    Infant = 'infant',
    Child = 'child',
    Youth = 'youth',
    Senior = 'senior',
    Student = 'student',
    Disabled = 'disabled',
    Veteran = 'veteran',
    Reduced = 'reduced',
    PersonalCareAttendant = 'personalCareAttendant',
    Other = 'other',
}

export enum SlotType {
    DriverBreak = 'driverBreak',
    Pickup = 'pickup',
    Dropoff = 'dropoff',
    StartLocation = 'startLocation',
    EndLocation = 'endLocation',
}

export interface Slot {
  id: string;
  slotStatus: SlotStatus;
  slotType: SlotType;
  fareCost: number | null;
  fareDiscount: number | null;
  fare: string | null;
  riders: { count: number, type: RiderType }[];
  dutyId: string;
  dutyIdentifier: string;
  driverId: string;
  driverFirstName: string;
  driverLastName: string;
  fleetId: string;
  fleetName: string;
  vehicleId: string;
  vehicleIdentifier: string;
  requestId: string;
  requestNotes: string | null,
  requestPickupNotes: string | null,
  requestDropoffNotes: string | null,
  riderId: string;
  riderFirstName: string | null;
  riderLastName: string | null;
  riderEmail: string | null;
  riderPhoneNumber: string | null;
  accessibilityFeatures: AccessibilityFeature[];
  scheduledTs: number;
  scheduledAddress: string;
  breakLength: number | null;
  driverEmail: string;
}

export interface Duty {
  dutyId: string;
  dutyStartTs: number;
  dutyEndTs: number;
  slots: Slot[];
}

export interface Data {
  recoveryEmail: string;
  driverFirstName: string;
  driverLastName: string;
  duties: Duty[];
}
