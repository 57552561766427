import React, { useState } from 'react';
import SlotTable from './components/SlotTable';
import { Login } from './Login';
import './index.css';
import { Data } from './components/types';
import { Helmet } from 'react-helmet';
import { ToastContainer } from './toast/ToastContainer';

const App: React.FC = () => {
  const [loginTime, setLoginTime] = useState<number | null>(() => {
    const storedLoginTime = localStorage.getItem('loginTime');
    if (storedLoginTime) {
      const parsedTime = parseInt(storedLoginTime, 10);
      const currentTime = Date.now();
      if (currentTime - parsedTime < 12 * 60 * 60 * 1000) {
        return parsedTime;
      }
    }
    return null;
  });

  const [data, setData] = useState<Data | null>(() => {
    const storedData = localStorage.getItem('data');
    if (storedData) {
      return JSON.parse(storedData);
    }
    return null;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  
  const login = async (organizationKey: string, username: string, password: string) => {
    setIsLoading(true);
    setLoginError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/itinerary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organizationKey, username, password }),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();

      const signedUrlResponse = await fetch(data.signedUrl);
      const signedUrlText = await signedUrlResponse.text();
      const signedUrlData = JSON.parse(signedUrlText);

      localStorage.setItem('loginTime', Date.now().toString());
      localStorage.setItem('data', JSON.stringify(signedUrlData));

      setLoginTime(Date.now());
      setData(signedUrlData);
    } catch (error) {
      console.error('Error:', error);
      setLoginError("Incorrect login details. Ask your dispatcher if you need help.");
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    setLoginTime(null);
    setData(null);
    localStorage.removeItem('loginTime');
    localStorage.removeItem('data');
  };

  const isLoggedIn = loginTime !== null;

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ width: '50px', height: '50px', border: '5px solid #f3f3f3', borderTop: '5px solid #3498db', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    );
  }

  if (!isLoggedIn) {
    return <Login login={login} loginError={loginError} />;
  }
        
  return (
    <>
      <Helmet>
        <title>Spare Driver Backup</title>
      </Helmet>
      <SlotTable
        slots={data?.duties[0].slots || []}
        driverName={`${data?.driverFirstName || ''} ${data?.driverLastName || ''}`}
        dutyId={data?.duties[0].dutyId || ''}
        recoveryEmail={data?.recoveryEmail || ''}
        logOut={logout}
      />
      <ToastContainer />
    </>
  );
};

export default App;
