import { Slot, SlotType } from "./types";
import styled from "styled-components";

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 600px;
  gap: 4px;
`

const Note = styled.div``

const Bold = styled.span`
  font-weight: bold;
`

interface IProps {
  slot: Slot;
}

export const SlotRequestNotes = ({ slot }: IProps) => {
  const { requestNote, slotTypeNote } = getNotes(slot);

  if (!requestNote && !slotTypeNote) {
    return null;
  }

  const slotType = slot.slotType === SlotType.Pickup ? 'Pickup' : 'Dropoff'
  
  return (
    <NotesContainer>
      {requestNote && (
        <Note>
          <Bold>Request note:</Bold> {requestNote}
        </Note>
      )}
      {slotTypeNote && (
        <Note>
          <Bold>{slotType} note:</Bold> {slotTypeNote}
        </Note>
      )}
    </NotesContainer>
  )
}

const getNotes = (slot: Slot): { requestNote: string | null, slotTypeNote: string | null }  => {
  switch(slot.slotType) {
    case SlotType.Pickup:
      return { requestNote: slot.requestNotes, slotTypeNote: slot.requestPickupNotes };
    case SlotType.Dropoff:
      return { requestNote: slot.requestNotes, slotTypeNote: slot.requestDropoffNotes }; 
    default:
      return { requestNote: null, slotTypeNote: null };
  }
}