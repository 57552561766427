import React from 'react'
import { ToastContainer as LibToastContainer } from 'react-toastify'
import './style.css'

export const ToastContainer = () => {
  return (
    <LibToastContainer
      position="bottom-left"
      autoClose={5000}
      closeButton={false}
      hideProgressBar={true}
      draggable={true}
      closeOnClick={true}
      toastClassName={'custom-toast'}
    />
  )
}
